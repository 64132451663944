export const GRID_ANSWER_SUBMIT = "GRID_ANSWER_SUBMIT";
export const GRID_ANSWER_LOAD = "GRID_ANSWER_LOAD";
export const GRID_ANSWER_SET = "GRID_ANSWER_SET";

export const CURRENT_CARD_SET = "CURRENT_CARD_SET";

export const CURRENT_USER_LOAD = "CURRENT_USER_LOAD";
export const CURRENT_USER_SET_PERMISSIONS = "CURRENT_USER_SET_PERMISSIONS";

export const CARD_ANSWER_REQUEST = "CARD_ANSWER_REQUEST";
export const CARD_ANSWER_SUCCESS = "CARD_ANSWER_SUCCESS";
export const CARD_ANSWER_FAILURE = "CARD_ANSWER_FAILURE";
export const CARD_ANSWER_REQUEST_START = "CARD_ANSWER_REQUEST_START";
export const CARD_ANSWER_REQUEST_END = "CARD_ANSWER_REQUEST_END";

export const BLOC_ANSWER_SAVE = "BLOC_ANSWER_SAVE";
export const BLOC_ANSWER_SET = "BLOC_ANSWER_SET";

export const ARRAY_ADD_NEW_LINE = "ARRAY_ADD_NEW_LINE";
export const ARRAY_DELETE_LINE = "ARRAY_DELETE_LINE";

export const ARRAY_LINE_SET = "ARRAY_LINE_SET";
export const ARRAY_LINE_UNSET = "ARRAY_LINE_UNSET";
