import {
  FormControl,
  InputLabel,
  Input,
  Grid,
  Button,
  Typography,
  FormHelperText,
  Tooltip,
} from "@material-ui/core";
import React from "react";
import { Field, submit } from "redux-form";
import { GaelField } from "../../types/types";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { useSnackbar } from "../Snackbar/atom";

export type BaseFieldProps = {
  input: any;
  meta: {
    error: boolean;
    touched: boolean;
  };
};

const MAX_SIZE = 5 * 1024 * 1024;

type Props = {
  placeholder: string;
  required: boolean;
  disabled: boolean;
  field: GaelField;
  helperText?: string;
  form: string;
  pristine: boolean;
  invalid: boolean;
  dispatch: Dispatch;
};

const DocumentField = ({
  input,
  placeholder,
  required,
  disabled,
  field,
  helperText,
  fullWidth,
}: Props & { fullWidth: boolean } & BaseFieldProps) => {
  const snackbar = useSnackbar();
  return (
    <FormControl
      fullWidth={fullWidth}
      required={!field.content && required}
      disabled={disabled}
    >
      <InputLabel shrink style={{ position: "static" }}>
        {placeholder}
      </InputLabel>
      <Input
        style={{ display: "none" }}
        onChange={(event: any) => {
          const file: File = event.target.files[0];
          if (file.size > MAX_SIZE) {
            snackbar('error', "Fichier trop gros, taille maximale 5 Mo");
          } else {
            input.onChange(file);
          }
        }}
        id={input.name}
        name={input.name}
        type="file"
      />
      <Grid container alignItems={"center"}>
        <Grid item>
          <label htmlFor={input.name}>
            <Button variant="contained" component="span" disabled={disabled}>
              <CloudUploadIcon />
              {input.value && input.value instanceof File ? (
                input.value.name
              ) : (
                <Typography style={{ marginLeft: 5 }}>
                  Charger un fichier
                </Typography>
              )}
            </Button>
          </label>
        </Grid>
        <Grid item style={{ marginLeft: 10 }}>
          {field.content && (
            <Button
              variant="contained"
              component="span"
              onClick={() => {
                window.open(field.content, "_blank");
              }}
            >
              <Tooltip title={"Téléchargement"} placement={"bottom"}>
                <>
                  <CloudDownloadIcon />
                  <Typography style={{ marginLeft: 5 }}>Télécharger</Typography>
                </>
              </Tooltip>
            </Button>
          )}
        </Grid>
      </Grid>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

const Document = (props: Props) => {
  const fullWidth = true;
  return (
    <Field
      onChange={() => {
        if (!props.invalid) {
          setTimeout(() => props.dispatch(submit(props.form)), 100);
        }
      }}
      component={DocumentField}
      {...props}
      fullWidth={fullWidth}
    />
  );
};

export default connect()(Document);
