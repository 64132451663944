import { TextField } from "@material-ui/core";
import React from "react";
import { Field, submit } from "redux-form";
import { connect } from "react-redux";
import { Dispatch } from "redux";

type Props = {
  type: string;
  placeholder: string;
  helperText: string;
  inTable: boolean;
  rows?: number;
  multiline?: boolean;
  form: string;
  pristine: boolean;
  invalid: boolean;
  dispatch: Dispatch;
};

interface TextFieldProps {
  label: string;
  input: string;
  meta: {
    touched: boolean;
    invalid: boolean;
    error: boolean;
  };
  multiline: boolean;
}

const renderTextField = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}: TextFieldProps) => (
  <TextField
    label={label}
    placeholder={label}
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...custom}
    multiline={custom.multiline}
  />
);

const BasicFieldComponent = ({
  type,
  placeholder,
  helperText,
  inTable,
  ...otherProps
}: Props) => {
  // console.log(inTable);
  // console.log(placeholder);
  return (
    <Field
      component={renderTextField}
      type={type}
      label={inTable ? "" : placeholder}
      placeholder={inTable ? "" : placeholder}
      helperText={helperText}
      {...otherProps}
      fullWidth
      onBlur={() => {
        if (!otherProps.pristine && !otherProps.invalid) {
          setTimeout(() => otherProps.dispatch(submit(otherProps.form)), 100);
        }
      }}
      multiline={inTable ? false : otherProps.multiline}
    />
  );
};

export default connect()(BasicFieldComponent);
