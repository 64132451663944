import React from "react";
import { connect } from "react-redux";
import Bloc from "../Bloc";
import { getCardAnswer } from "../../actions/actionCreators";
import { getCurrentCard } from "../../selectors";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import { CardAnswer } from "../../types/types";
import { AppState } from "../../store/configureStore";

interface BlocListProps {
  card: CardAnswer;
  canWrite: boolean;
  fetching: boolean;
  error: boolean;
  getCardAnswer: () => void;
}

class BlocList extends React.Component<BlocListProps> {
  componentDidMount() {
    this.props.getCardAnswer();
  }

  componentDidUpdate(prevProps: any) {
    // This is required for etude cards, where the BlocList component is updated
    // and not unmounted/mounted in the React DOM tree
    if (prevProps.card.id !== this.props.card.id) {
      this.props.getCardAnswer();
    }
  }

  render() {
    const { card, canWrite, fetching, error } = this.props;

    if (error) {
      console.log("Error in BlocList: ", error)
      return (
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item>
            <Typography color="error" gutterBottom>
              Une erreur s'est produite.
            </Typography>
          </Grid>
          <Grid item>
            <Typography color="error">{error}</Typography>
          </Grid>
        </Grid>
      );
    }

    return card ? (
      <React.Fragment>
        <Grid
          container
          direction={"column"}
          spacing={6}
          style={{ paddingBottom: 24 }}
        >
          {card.description && (
            <Grid item>
              <Typography
                dangerouslySetInnerHTML={{ __html: card.description }}
              />
            </Grid>
          )}
          {fetching ? (
            <Grid item>
              <Grid
                container
                style={{ justifyContent: "center", paddingTop: "20px" }}
              >
                <Grid item>
                  <CircularProgress />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            card.blocs.map((bloc: any) => (
              <Grid item key={bloc.id}>
                <Bloc bloc={bloc} canWrite={canWrite} />
              </Grid>
            ))
          )}
        </Grid>
      </React.Fragment>
    ) : null;
  }
}

const mapStateToProps = (state: AppState) => ({
  card: getCurrentCard(state, null),
  canWrite: state.currentUser.permissions.CAN_WRITE,
  fetching: state.gridAnswer.fetchingCardAnswer,
  error: state.gridAnswer.error,
});

const mapDispatchToProps = {
  getCardAnswer,
};

export default connect(mapStateToProps, mapDispatchToProps)(BlocList);
