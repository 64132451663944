import { FormHelperText, TextField } from "@material-ui/core";
import React from "react";
import { Field, submit } from "redux-form";
import { connect } from "react-redux";
import { Dispatch } from "redux";

interface RenderTextFieldProps {
  label: string;
  input: string;
  meta: {
    touched: boolean;
    invalid: boolean;
    error: boolean;
  };
}

const renderTextField = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}: RenderTextFieldProps) => {
  return (
  <>
    <FormHelperText>{label}</FormHelperText>
    <TextField
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      {...input}
      {...custom}
    />
  </>
)};

type Props = {
  component?: any;
  options?: any[];
  inTable: boolean;
  helperText: string;
  placeholder: string;
  form: string;
  pristine: boolean;
  invalid: boolean;
  dispatch: Dispatch;
};

function SelectFieldComponent(props: Props) {
  const {
    options,
    inTable,
    helperText,
    placeholder,
    form,
    pristine,
    invalid,
    ...otherProps
  } = props;
  return (
    <Field
      component={props.component || renderTextField}
      {...otherProps}
      helperText={inTable ? null : helperText}
      label={inTable ? null : placeholder}
      onChange={() => {
        if (!invalid) {
          setTimeout(() => otherProps.dispatch(submit(form)), 100);
        }
      }}
      SelectProps={{ native: true }}
      fullWidth
      select
    >
      <option key="empty" value="" />
      {options?.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </Field>
  );
}

export default connect()(SelectFieldComponent);
