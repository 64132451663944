import React from "react";
import moment, { Moment } from "moment";
import { Field, submit } from "redux-form";
import _omit from "lodash/omit";
import _omitBy from "lodash/omitBy";
import _isNil from "lodash/isNil";
import { DatePicker } from "@material-ui/pickers";
import { GaelField } from "../../types/types";
import { BaseFieldProps } from "./Document";
import { Dispatch } from "redux";
import { connect } from "react-redux";

interface DatePickerProps {
  label?: string;
  value?: Moment | null;
  minDate?: string | number | undefined;
  maxDate?: string | number | undefined;
  onChange?: (value: Moment) => void;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  helperText?: string | boolean;
  onBlur?: () => void;
}

export const DatePickerComponent = (props: DatePickerProps) => {
  let limitDates1 = _omitBy(
    {
      minDate: props.minDate ? moment(props.minDate) : null,
      maxDate: props.maxDate ? moment(props.maxDate) : null,
    },
    _isNil
  );

  const limitDates = _omitBy(
    {
      ...limitDates1,
      minDateMessage: limitDates1.minDate
        ? `La date ne peut pas être inférieure au ${limitDates1.minDate.format(
            "DD/MM/YYYY"
          )}`
        : null,
      maxDateMessage: limitDates1.maxDate
        ? `La date ne peut pas être supérieure au ${limitDates1.maxDate.format(
            "DD/MM/YYYY"
          )}`
        : null,
    },
    _isNil
  );

  return (
    <DatePicker
      // @ts-ignore
      keyboard
      fullWidth
      clearable
      animateYearScrolling={false}
      format="DD/MM/YYYY"
      cancelLabel={"Annuler"}
      clearLabel={"Réinitialiser"}
      invalidDateMessage={"Format de date invalide"}
      invalidLabel={"Date non reconnue"}
      container="inline"
      placeholder={moment().format("DD/MM/YYYY")}
      mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
      {..._omit(props, ["minDate", "maxDate"])}
      {...limitDates}
    />
  );
};

type DateFieldProps = {
  placeholder: string;
  field: GaelField;
  required: boolean;
  disabled: boolean;
  inTable: boolean;
  form: string;
  pristine: boolean;
  invalid: boolean;
};

const DateField = (
  props: DateFieldProps &
    BaseFieldProps & {
      dispatch: Dispatch;
      formName: string;
      isPristine: boolean;
    }
) => {
  const {
    input,
    placeholder,
    field,
    meta,
    required,
    disabled,
    inTable,
    formName,
    invalid,
    dispatch
  } = props;
  
  return (
    <DatePickerComponent
      label={inTable ? "" : placeholder}
      value={input.value ? moment(input.value) : null}
      onChange={(value: Moment) => {
        input.onChange(value.format("YYYY-MM-DD"));
        if (!invalid) {
          setTimeout(() => dispatch(submit(formName)), 100);
        }
      }}
      disabled={disabled}
      required={required}
      maxDate={field.restrictions.maximum}
      minDate={field.restrictions.minimum}
      error={meta.error}
      helperText={meta.error ? meta.error : field.hint}
    />
  );
};

const DateFieldConnected = connect()(DateField);

const DateFieldComponent = (props: DateFieldProps) => {
  // console.log(props);
  return (
    <Field
      component={DateFieldConnected}
      formName={props.form}
      isPristine={props.pristine}
      {...props}
    />
  );
};

export default DateFieldComponent;
