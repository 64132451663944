import moment from "moment";

export const number = (value: any) =>
  value && isNaN(Number(value)) ? "Doit être un nombre" : undefined;

export const required = (value: string | any[]) =>
  value && (!Array.isArray(value) || value.length > 0) ? undefined : "Requis";

export const minValue = (min: number) => (value: number) =>
  value && value < min
    ? `Merci de renseigner un nombre supérieur à ${min}`
    : undefined;

export const maxValue = (min: number) => (value: number) =>
  value && value > min
    ? `Merci de renseigner un nombre inférieur à ${min}`
    : undefined;

export const maxLength = (max: number) => (value: string | any[]) =>
  value && value.length > max
    ? `Doit avoir ${max} charactères au maximum`
    : undefined;

export const minDate = (min: moment.MomentInput) => (
  value: moment.MomentInput
) =>
  value && moment(value).isBefore(moment(min))
    ? `Merci de renseigner une date supérieure au ${min}`
    : undefined;

export const maxDate = (max: moment.MomentInput) => (
  value: moment.MomentInput
) =>
  value && moment(value).isAfter(moment(max))
    ? `Merci de renseigner une date inférieure au ${max}`
    : undefined;

export const count = (c: number) => (value: string | any[]) =>
  value && value.length !== c
    ? `Merci de selectionner ${c} element${c > 1 ? "s" : null}`
    : undefined;
