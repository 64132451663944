import { put, select, takeLatest } from "redux-saga/effects";
import { CURRENT_USER_LOAD } from "../../actions/actionTypes";
import api from "../../api";
import { setCurrentUserPermissions } from "../../actions/actionCreators";
import { AppState } from "../../store/configureStore";
import { ActionContent } from "../../types/types";
import { UserState } from "../../reducers/currentUser";

const getCurrentUser = (state: AppState) => state.currentUser;

const getGridAnswerStatus = (state: AppState) => state.gridAnswer.state;

const hasUserSubmitted = (
  state: AppState,
  isAuditor: boolean,
  isDebriefer: boolean
) => {
  const {
    gridAnswer: { submissions = [] },
    currentUser: { id },
  } = state;

  if (isAuditor) {
    return (
      submissions.find(
        (sub) => sub.action === "auditors" && sub.user.id === id
      ) !== undefined
    );
  } else if (isDebriefer) {
    return (
      submissions.find(
        (sub) => sub.action === "debriefers" && sub.user.id === id
      ) !== undefined
    );
  }
  return false;
};

function* determineCurrentUserPermissions(
  loadCurrentUserAction: ActionContent<UserState>
) {
  const gridAnswerStatus: string = yield select(getGridAnswerStatus);

  const currentUser: UserState = loadCurrentUserAction
    ? loadCurrentUserAction.payload
    : yield select(getCurrentUser);

  const {
    is_auditor: isAuditor,
    is_debriefer: isDebriefer,
    is_president: isPresident,
  } = currentUser;

  const hasSubmitted: boolean = yield select(
    hasUserSubmitted,
    isAuditor,
    isDebriefer
  );

  api.defaults.headers.common["Authorization"] = currentUser.Authorization;

  const permissions = (() => {
    switch (gridAnswerStatus) {
      case "initialized":
        return {
          CAN_WRITE: !(isAuditor || isDebriefer),
          CAN_SUBMIT: isPresident,
        };
      case "in_audit":
        return {
          CAN_WRITE: isAuditor,
          CAN_SUBMIT: isAuditor && !hasSubmitted,
        };
      case "in_debrief":
        return {
          CAN_WRITE: isDebriefer,
          CAN_SUBMIT: isDebriefer && !hasSubmitted,
        };
      default:
        return {
          CAN_WRITE: false,
          CAN_SUBMIT: false,
        };
    }
  })();

  yield put(setCurrentUserPermissions(permissions));
}

const exported = [
  takeLatest(CURRENT_USER_LOAD, determineCurrentUserPermissions),
];

export default exported;
