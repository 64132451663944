import { takeLatest, select, call, put } from "redux-saga/effects";
import _get from "lodash/get";
import {
  CARD_ANSWER_REQUEST,
  CARD_ANSWER_FAILURE,
  CARD_ANSWER_SUCCESS,
  CARD_ANSWER_REQUEST_START,
  CARD_ANSWER_REQUEST_END,
} from "../../actions/actionTypes";
import {
  getGridAnswerId,
  getCurrentCardId,
  getEtudeCardPath,
} from "../../selectors";
import api from "../../api";
import { CardBloc } from "../../types/types";

function* getCardAnswer() {
  try {
    yield put({ type: CARD_ANSWER_REQUEST_START, fetching: true });
    const currentCardId: number = yield select(getCurrentCardId);
    const gridAnswerId: number = yield select(getGridAnswerId);

    const { data } = yield call(
      api.get,
      `/grid_answers/${gridAnswerId}/card_answers/${currentCardId}`
    );

    let card;
    if (data.etude_id) {
      const etudeCardPath: string = yield select(getEtudeCardPath);
      card = {
        ...data,
        path:
          data.path.split("/").length > 1
            ? `${etudeCardPath}/${data.etude_id}/${data.path
                .split("/")
                .slice(1)
                .join("/")}`
            : `${etudeCardPath}/${data.etude_id}`,
      };
    } else {
      card = data;
    }
    card.blocs.sort((a: CardBloc, b: CardBloc) => a.position - b.position);

    yield put({ type: CARD_ANSWER_SUCCESS, card });
  } catch (error) {
    console.log("error", error);
    yield put({
      type: CARD_ANSWER_FAILURE,
      error: _get(error, "response.data.message", "Erreur inconnue"),
    });
  } finally {
    yield put({ type: CARD_ANSWER_REQUEST_END, fetching: false });
  }
}

const exported = [takeLatest(CARD_ANSWER_REQUEST, getCardAnswer)];

export default exported;
