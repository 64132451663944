import { Grid, Button } from "@material-ui/core";
import React from "react";
import RightArrowIcon from "@material-ui/icons/ArrowRight";
import { Link } from "react-router-dom";

const Breadcrumb = ({ url }: { url: string }) => {
  const nodeList: Array<string> = url
    .split("/")
    .filter(String)
    .map(decodeURIComponent);

  return (
    <Grid container alignItems={"center"} spacing={1}>
      <Grid item>
        <Button component={Link} to={"/"} size="small">
          Accueil
        </Button>
      </Grid>
      {nodeList.map((node, index, list) => (
        <React.Fragment key={node}>
          <Grid
            item
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <RightArrowIcon />
          </Grid>
          <Grid item>
            <Button
              size={"small"}
              component={Link}
              to={`/${list.slice(0, index + 1).join("/")}`}
            >
              {node}
            </Button>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default Breadcrumb;
