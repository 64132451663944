import { getRecoil, setRecoil } from "recoil-nexus";
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  CURRENT_USER_LOAD,
  GRID_ANSWER_LOAD,
  GRID_ANSWER_SUBMIT,
} from "../../actions/actionTypes";
import _reduce from "lodash/reduce";
import _get from "lodash/get";
import { loadGridAnswer, setGridAnswer } from "../../actions/actionCreators";
import api from "../../api";
import { getGridAnswerId } from "../../selectors";
import {
  ActionContent,
  CardAnswer,
  CardBloc,
  GridAnswer,
  GridAnswerFlattener,
  SagaPayload,
} from "../../types/types";
import { useRecoilState } from "recoil";
import { savedAtom } from "../../api/submiter";

/**
 * This function is used to go from a tree of cards to a flatten object of card by their id
 * @param acc
 * @param card
 * @returns {{[p: string]: *}}
 */
const flattenCards = (acc: {}, card: CardAnswer): GridAnswerFlattener => {
  if (card.cards.length > 0) {
    return {
      ...acc,
      ...card.cards.reduce(flattenCards, {}),
      [card.id]: {
        ...card,
      },
    };
  } else {
    return {
      ...acc,
      [card.id]: card,
    };
  }
};

const etudePath = (path: string, parentPath?: string): string =>
  parentPath
    ? parentPath + "/" + path.split("/").slice(-1)[0].trim()
    : path.trim();

/**
 * This function is used to go from a tree of etude cards to a flatten object of card by their id
 * @param acc
 * @param card
 * @returns {{[p: string]: *}}
 */
const flattenEtudeCards = (
  acc: {},
  card: CardAnswer,
  parentPath?: string
): GridAnswerFlattener => {
  const newPath = etudePath(card.path, parentPath);
  const newCard = {
    ...card,
    path: newPath,
    name: card.name.trim(),
    cards: [
      ..._get(card, "cards", [])
        .sort((a, b) => a.position - b.position)
        .map((c) => ({ ...c, path: etudePath(c.path, newPath) })),
    ],
    blocs: [..._get(card, "blocs", []).sort((a, b) => a.position - b.position)],
  };

  if (card.cards.length > 0) {
    return {
      ...acc,
      ...card.cards.reduce(
        (accumulator, c) => flattenEtudeCards(accumulator, c, newPath),
        {}
      ),
      [card.id]: newCard,
    };
  } else {
    return {
      ...acc,
      [card.id]: newCard,
    };
  }
};

/**
 * This functions start from a tree of cards,
 * flattens them and then sort their child cards and blocs by position
 * @param cards
 * @returns {*}
 */
const cardListToSet = (cards: Array<CardAnswer>): GridAnswerFlattener =>
  _reduce(
    cards.reduce(flattenCards, {}),
    (acc, card: CardAnswer) => ({
      ...acc,
      [card.id]: {
        ...card,
        path: card.path.trim(),
        name: card.name.trim(),
        cards: [
          ..._get(card, "cards", []).sort(
            (a: CardAnswer, b: CardAnswer) => a.position - b.position
          ),
        ],
        blocs: [
          ..._get(card, "blocs", []).sort(
            (a: CardBloc, b: CardBloc) => a.position - b.position
          ),
        ],
      },
    }),
    {}
  );

/**
 * This functions start from a tree of cards,
 * flattens them and then sort their child cards and blocs by position
 * @param cards
 * @returns {*}
 */
const etudeCardListToSet = (cards: Array<CardAnswer>) =>
  cards.reduce((acc, c) => flattenEtudeCards(acc, c), {});

function* cleanAndSetGridAnswer(
  loadGridAnswerAction: ActionContent<GridAnswer>
) {
  const gridAnswer = loadGridAnswerAction.payload;

  // edit path property for etude cards and re-arrange from array to object by id
  const etudeCardAnswerSet = etudeCardListToSet(
    [...gridAnswer.exhaus, ...gridAnswer.deonto].map((card) => ({
      ...card,
      path: `${gridAnswer.etudes.path}/${card.etude_id}`,
    }))
  );

  // merge all the cards together (etude and default)
  const cardAnswerSet = {
    ...cardListToSet(gridAnswer.card_answers),
    ...etudeCardAnswerSet,
  };

  const cleanedGridAnswer = {
    ...gridAnswer,
    cardAnswerSet,
  };

  yield put(setGridAnswer(cleanedGridAnswer));
}

function* submitGridAnswer() {
  
  
  try {
    const gridAnswerId: number = yield select(getGridAnswerId);
    const submitGridAnswerRequest: SagaPayload = yield call(
      api.patch,
      `/grid_answers/${gridAnswerId}/submit`
    );

    yield put(loadGridAnswer(submitGridAnswerRequest.data));
    yield put({ type: CURRENT_USER_LOAD });
    setRecoil(savedAtom, { saving: false, success: true });
    console.log('setting');
  } catch (error) {
    setRecoil(savedAtom, { saving: false, success: false });
    console.error(error);
  }
}

const exported = [
  takeLatest(GRID_ANSWER_LOAD, cleanAndSetGridAnswer),
  takeLatest(GRID_ANSWER_SUBMIT, submitGridAnswer),
];

export default exported;
