import axios from "axios";
import { setRecoil } from "recoil-nexus";
import env from "../env";
import { binder } from './globals';
import { savedAtom } from "./submiter";

const api = axios.create({
  // eslint-disable-next-line no-restricted-globals
  baseURL: env.gaelURL,
});

type Data = {
  start: boolean;
  result: boolean;
}

export class CustomEvent extends Event {
  public data: Data;
  constructor(type: string, data: Data) {
    super(type);
    this.data = data;
  }
}

binder.register(({ snackbar }) => {
  api.interceptors.response.use(resp => {
    setRecoil(savedAtom, { saving: false, success: true });
    window.dispatchEvent(new CustomEvent("fetch", { start: false, result: true }));
    return resp;
  }, err => {
    window.dispatchEvent(new CustomEvent("fetch", { start: false, result: false }));
    snackbar('error', "Failed");
    return err;
  });

  api.interceptors.request.use(resp => {
    console.log('setting');
    window.dispatchEvent(new CustomEvent("fetch", { start: true, result: true }));
    return resp;
  }, err => {
    window.dispatchEvent(new CustomEvent("fetch", { start: true, result: false }));
    snackbar('error', "Failed");
    return err;
  });
});




export default api;
