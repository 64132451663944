import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";

import { loadCurrentUser, loadGridAnswer } from "../actions/actionCreators";
import keycloak from "../keycloak";
import rootReducer from "../reducers";
import { UserState } from "../reducers/currentUser";
import rootSaga from "../sagas";
import { FormState, StateGridAnswer } from "../types/types";
import devUser from "./CurrentUser.json";
import devGrid from "./GridAnswer.json";

export const isDev = () =>
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export const MIDDLEWARE_URL = "https://gael-middle.beta.junior-entreprises.com";
//export const MIDDLEWARE_URL = process.env.REACT_APP_MIDDLEWARE_URL || "http://localhost:8000";

const forceProd = true;

const gaelUrl = "https://gael.junior-entreprises.com";

export const getGaelGridUrl = (token: string, auditId: number) => {
  // return `/login/access_token?access-token=${token}&audit_id=${auditId}`;
  return `${gaelUrl}/access_token?access-token=${token}&audit_id=${auditId}`;
};


const getData = async (auditId: number, token: string) => {
  let gridAnswer: any = null;
  let currentUser: any = null;
  if (isDev() && !forceProd) {
    gridAnswer = devGrid;
    currentUser = devUser;
  } else {
    const resp = await fetch(MIDDLEWARE_URL + "/login", {
      method: "POST",
      body: JSON.stringify({url: getGaelGridUrl(token, auditId)}),
    });
    const res: { user: string; grid: string } = await resp.json();
    gridAnswer = JSON.parse(res.grid);
    currentUser = JSON.parse(res.user);
  }
  return { gridAnswer, currentUser, token };
};

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

export type AppState = {
  currentUser: UserState;
  form: FormState;
  gridAnswer: StateGridAnswer;
};

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);

sagaMiddleware.run(rootSaga);

const disconnectedUser = {
  id: -1,
  is_auditor: false,
  is_debriefer: false,
  is_president: false,
};

export const loadGrid = (auditId: number, token: string, tokenOnly : boolean) => {
  return getData(auditId, token)
    .then(({ gridAnswer, currentUser, token }) => {
      if (token !== "") {
        if (!tokenOnly) {
            store.dispatch(loadGridAnswer(gridAnswer));
        }
        store.dispatch(loadCurrentUser(currentUser));
      } else {
        // @ts-ignore
        store.dispatch(loadCurrentUser(disconnectedUser));
      }
    })
    .then(() => {
      if (window.location.href.includes("url=")) {
        window.history.replaceState("", "", "/grid_answers/uid");
      }
    });
};
export const logout = () => {
  keycloak.logout();
  // @ts-ignore
  store.dispatch(loadCurrentUser(disconnectedUser));
  window.location.reload();
};

export default store;
