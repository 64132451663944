import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  List,
} from "@material-ui/core";
import AssignmentIcon from "@material-ui/icons/Assignment";
import FolderIcon from "@material-ui/icons/Folder";
import _isEmpty from "lodash/isEmpty";
import _trim from "lodash/trim";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getCurrentCard } from "../../selectors";
import { AppState } from "../../store/configureStore";
import { CardAnswer } from "../../types/types";

interface TreeItemProps {
  item: CardAnswer;
}

const TreeItem = ({ item }: TreeItemProps) => {
  return (
    item && (
      <ListItem
        divider
        button
        component={Link}
        to={`/${item.path
          .split("/")
          .map(_trim)
          .map(encodeURIComponent)
          .join("/")}`}
      >
        <ListItemIcon>
          {item.cards.length > 0 ? <FolderIcon /> : <AssignmentIcon />}
        </ListItemIcon>
        <ListItemText primary={item.name} />
      </ListItem>
    )
  );
};

interface TreeProps {
  cards: {
    cards: Array<CardAnswer>;
  };
}

const Tree = ({ cards }: TreeProps) => (
  <Paper>
    <List disablePadding>
      {cards.cards &&
        cards.cards.map((item) => <TreeItem item={item} key={item.id} />)}
    </List>
  </Paper>
);

const mapStateToProps = (state: AppState) => ({
  cards:
    _isEmpty(state.gridAnswer) || !state.gridAnswer.currentCardId
      ? { cards: state.gridAnswer.card_answers }
      : getCurrentCard(state, null),
});

export default connect(mapStateToProps)(Tree);
