import { useCallback } from "react";
import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import { useAlert } from "../components/Alert/atom";
import { useSnackbar } from "../components/Snackbar/atom";
import { Binder } from "../utils/binding";
import { savedAtom } from "./submiter";


type BasicBinder = {
    snackbar: ReturnType<typeof useSnackbar>;
    alert: ReturnType<typeof useAlert>;
    history: ReturnType<typeof useHistory>;
    savingState: ReturnType<typeof useSavingState>;
}

// const binds = {
//     snackbar: useSnackbar,
//     alert: useAlert,
//     history: useHistory,
// }

export const binder = new Binder<BasicBinder>();

const useSavingState = () => {
    return useRecoilState(savedAtom);
}

export function useBindGlobal() {
    const snackbar = useSnackbar();
    const alert = useAlert();
    const history = useHistory();
    const savingState = useSavingState();

    const bind = useCallback(() => {
        binder.init({ snackbar, alert, history, savingState });
        binder.bind();
        console.log('bound');
    }, [snackbar, useAlert, history]);

    return bind;
}
