import { CircularProgress } from "@material-ui/core";
import { Check, Clear } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { atom, useRecoilState } from "recoil";
import { submit } from "redux-form";
import React, { useEffect, useState } from 'react';
import { CustomEvent } from './index';

type SavedState = {
  success: boolean;
  saving: boolean;
};

export const savedAtom = atom<SavedState>({
  key: '',
  default: {
    saving: false,
    success: false,
  }
});


export function makeSubmiter() {

  let timeout: number;

  function useSubmiter(form: string) {
    const dispatch = useDispatch();
    const [state, setState] = useRecoilState(savedAtom);

    const s = () => {
      if (timeout) {
        window.clearTimeout(timeout);
      }
      timeout = window.setTimeout(() => {
        setState({
          saving: true,
          success: false,
        })
        dispatch(submit(form));
      }, 500);
    };

    return s;
  }

  return useSubmiter;
}


export default makeSubmiter();



export const SavingState = () => {
  const [{ saving, success }, setState] = useState({ success: false, saving: false });
  
  useEffect(() => {
    window.addEventListener('fetch', (e) => {
      const evt = e as CustomEvent;
      if (evt.data.start) {
        setState({ saving: true, success: false });
      } else {
        if (evt.data.result) {
          setState({ saving: false, success: true });
        } else {
          setState({ saving: false, success: false });
        }
      }
    })
  }, []);

  if (saving) {
    return <CircularProgress style={{ color: "white" }} />
  }
  return (
    <>
      {success ? <Check /> : <Clear />}
    </>
  );
}