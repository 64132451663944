export type BinderFragment<T> = (params: T) => void

/**
 * A class to hold informations in order to use hook in global functions
 */
export class Binder<T extends {}> {
    public readonly funcs: BinderFragment<T>[] = [];
    private params?: T;
    constructor() {

    }

    /**
     * Register callback to be executed when the context is ready
     * @param func 
     */
    register(func: BinderFragment<T>) {
        this.funcs.push(func);
    }

    /**
     * Initliazes the binder with all the required fields
     * @param params 
     */
    init(params: T) {
        this.params = params;
    }

    /**
     * Executes all the binder with the current context
     */
    bind() {
        const params = this.params;
        if (params) {
            this.funcs.forEach(f => {
                console.log('binding ', f);
                f(params);
            });
            console.log('bound globals to context', params, this.funcs);
        } else {
            throw new Error('Not initialized');
        }

    }
}


export function makePersistor<T>(name: string) {
    function get(): T | null {
      const d = localStorage.getItem(name);
      if (d) {
        return JSON.parse(d);
      } else {
        return null;
      }
    }
    const set = (data: T) => {
      localStorage.setItem(name, JSON.stringify(data));
    };
    return { get, set };
  }