import { all } from "redux-saga/effects";

import gridAnswer from "./gridAnswer";
import blocAnswer from "./blocAnswer";
import user from "./user";
import array from "./array";
import cardAnswer from "./cardAnswer";

export default function* rootSaga() {
  yield all([...gridAnswer, ...blocAnswer, ...user, ...array, ...cardAnswer]);
}
