import * as Actions from "./actionTypes";
import { CardAnswer, Etude, GridAnswer, Line } from "../types/types";
import { UserState } from "../reducers/currentUser";

export const loadGridAnswer = (gridAnswer: GridAnswer) => ({
  type: Actions.GRID_ANSWER_LOAD,
  payload: gridAnswer,
});

export const setGridAnswer = (gridAnswer: GridAnswer) => ({
  type: Actions.GRID_ANSWER_SET,
  payload: gridAnswer,
});

export const submitGridAnswer = () => ({
  type: Actions.GRID_ANSWER_SUBMIT,
});

export const loadCurrentUser = (currentUser: UserState) => ({
  type: Actions.CURRENT_USER_LOAD,
  payload: currentUser,
});

export const setCurrentUserPermissions = (permissions: {
  CAN_WRITE: boolean;
  CAN_SUBMIT: boolean;
}) => ({
  type: Actions.CURRENT_USER_SET_PERMISSIONS,
  payload: permissions,
});

export const setCurrentCard = (card: CardAnswer) => ({
  type: Actions.CURRENT_CARD_SET,
  payload: card,
});

export const getCardAnswer = () => ({
  type: Actions.CARD_ANSWER_REQUEST,
});

export const saveBlocAnswer = (
  answers: any,
  dispatch: any,
  formProps: any,
  resolve: any,
  reject: any
) => ({
  type: Actions.BLOC_ANSWER_SAVE,
  payload: {
    answers,
    formProps,
  },
  resolve,
  reject,
});

export const setBlocAnswer = (blocAnswer: any) => ({
  type: Actions.BLOC_ANSWER_SET,
  blocAnswer,
});

export const addNewLineToArray = (
  arrayId: number,
  etudeArray: Etude[],
) => ({
  type: Actions.ARRAY_ADD_NEW_LINE,
  payload: {
    arrayId,
    etudeArray,
  },
});

export const deleteLineFromArray = (
  arrayId: any,
  lineId: any,
  etudeArray: any
) => ({
  type: Actions.ARRAY_DELETE_LINE,
  payload: {
    arrayId,
    lineId,
    etudeArray,
  },
});

export const setLineInArray = (arrayId: number, line: Line) => ({
  type: Actions.ARRAY_LINE_SET,
  payload: {
    arrayId,
    line,
  },
});

export const unsetLineInArray = (arrayId: number, lineId: number) => ({
  type: Actions.ARRAY_LINE_UNSET,
  payload: {
    arrayId,
    lineId,
  },
});
