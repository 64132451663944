import {
  AppBar as MUIAppBar,
  Button,
  Chip,
  Grid,
  Theme,
  Toolbar,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import CloseIcon from "@material-ui/icons/Close";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import MenuIcon from "@material-ui/icons/Menu";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import { makeStyles } from "@material-ui/styles";
import { useKeycloak } from "@react-keycloak/web";
import clsx from "clsx";
import React from "react";
import { connect } from "react-redux";
import { SavingState } from "../../../api/submiter";
import { UserState } from "../../../reducers/currentUser";
import { AppState, logout } from "../../../store/configureStore";
import DarkModeToggle from "../../DarkModeToggle/DarkModeToggle";
import { getAudit } from "../../GridView";
import SideMenu from "../SideMenu";
import GaelLogo from "./logo";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawer: {
    willChange: "transform",
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  drawerClose: {
    width: 0,
  },
  drawerOpen: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const gaelUrl = "https://gael.junior-entreprises.com";

export const getGaelGridUrl = (token: string, auditId: number) => {
  return `${gaelUrl}/access_token?access-token=${token}&audit_id=${auditId}`;
};


const AppBar = ({ email, is_auditor, is_debriefer }: UserState) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState<boolean>(false);
  const {keycloak} = useKeycloak();

  const handleDrawerOpen = (): void => {
    setOpen(!open);
  };

  const toGaelOld = () => {
    const audit = getAudit();
    if (audit && keycloak.token) {
      const url = getGaelGridUrl(keycloak.token, audit.auditId);
      window.location.replace(url);
    }
  }

  return (
    <>
      <MUIAppBar position={"absolute"} className={classes.appBar}>
        <Toolbar>
          <Grid
            container
            alignItems={"center"}
            style={{
              height: "64px",
            }}
            spacing={2}
          >
            <Grid item style={{ display: "flex", alignItems: "left" }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
              >
                {" "}
                {open ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
            </Grid>
            <Grid item xs>
              <GaelLogo />
            </Grid>
            <Grid item >
              <Button onClick={toGaelOld}>
                Gael-old
              </Button>
            </Grid>
            <Grid item >
              <SavingState />
            </Grid>
            <Grid item >
              <DarkModeToggle />
            </Grid>
            <Grid item >
              <Chip
                avatar={
                  <Avatar>{email && email.charAt(0).toUpperCase()}</Avatar>
                }
                label={email}
              />
            </Grid>
            <Grid item>
              {is_auditor && (
                <Chip icon={<AssignmentIndIcon />} label={"Auditeur"} />
              )}
            </Grid>
            <Grid item>
              {is_debriefer && (
                <Chip icon={<HowToRegIcon />} label={"Debriefeur"} />
              )}
            </Grid>
            <Grid item>
              {!is_auditor && !is_debriefer && (
                <Chip icon={<PeopleOutlineIcon />} label={"Junior"} />
              )}
            </Grid>
            <Grid item>
              <IconButton onClick={logout}>
                <ExitToAppIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </MUIAppBar>
      <Drawer
        variant="persistent"
        anchor="left"
        open={open}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <Divider style={{ paddingTop: "60px" }} />
        <SideMenu />
      </Drawer>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  ...state.currentUser,
});

export default connect(mapStateToProps)(AppBar);
