import { createSelector, OutputParametricSelector } from "reselect";
import _find from "lodash/find";
import _get from "lodash/get";
import { AppState } from "../store/configureStore";

const getCards = (state: AppState, ownProps: any) =>
  state.gridAnswer.cardAnswerSet;

const getPath = (_: AppState, ownProps: any) =>
  decodeURIComponent(ownProps.match.url).substring(1);

export const getCurrentCardFromPath: OutputParametricSelector<
  any,
  any,
  any,
  any
> = createSelector([getCards, getPath], (cards, path) => {
  const card = _find(cards, (c) => c.path === path);
  return card && card.id;
});

export const getCurrentCardId = (state: AppState, _?: any): number =>
  state.gridAnswer.currentCardId;

export const getCurrentCard = createSelector(
  [getCards, getCurrentCardId],
  (cards, cardId) => cards[cardId]
);

export const getGridAnswerId = (state: AppState): number => state.gridAnswer.id;

export const getEtudeCardPath = (state: AppState): string =>
  _get(state, "gridAnswer.etudes.path", "");
