import React from "react";

const GaelLogo = () => (
  <svg width={132} height={41} viewBox="0 0 1328 414" fill="none">
    <path
      d="M816.944 314.6C805.803 340.967 788.939 364.972 766.721 385.136C758.942 392.196 749.657 399.551 738.672 406.738H824.562H1019C1031.07 406.738 1040.84 398.727 1040.84 388.846V314.6H816.944Z"
      fill="white"
    />
    <path
      d="M980.739 252.243V252.217H958.034C957.796 252.234 957.556 252.243 957.314 252.243H835.137H781.17C788.072 245.007 793.906 237.601 798.794 230.494C812.754 210.192 823.351 186.022 830.351 159.475H971.035V159.492H980.739H1040.84V252.243H980.739Z"
      fill="white"
    />
    <path
      d="M832.577 99.5942V99.5273H807.721C811.225 92.3618 814.189 85.0286 816.671 77.9901C823.683 58.106 829.021 34.4713 832.577 8.52267V7.45828H1027.84C1035.02 7.45828 1040.84 12.228 1040.84 18.1125V99.5942H832.577Z"
      fill="white"
    />
    <path
      d="M1084.67 408.2V7.2002H1158.05V344.281H1327.67V408.2H1084.67Z"
      fill="white"
    />
    <path
      d="M316.2 197.4V375C279 400.6 232.4 413.4 176.4 413.4C120.8 413.4 77.4 396.2 46.2 361.8C15.4 327 0 275.6 0 207.6C0 170.4 5.2 138 15.6 110.4C26 82.4 39.8 60.8 57 45.6C74.6 30 93.2 18.6 112.8 11.4C132.8 3.8 153.8 0 175.8 0C235.4 0 282.6 24 317.4 72L264.6 111.6C238.2 78.4 208.4 61.8 175.2 61.8C144 61.8 119.4 74.2 101.4 99C83.8 123.8 75 158.4 75 202.8C75 303.6 109.8 354 179.4 354C207 354 228.2 349.2 243 339.6V252.6H186.6V197.4H316.2Z"
      fill="white"
    />
    <path
      d="M428.344 406.2H357.544L495.544 7.20001H577.144L718.144 406.2H640.144L604.144 300.6H463.744L428.344 406.2ZM533.944 74.4L478.144 245.4H589.744L535.744 74.4H533.944Z"
      fill="white"
    />
  </svg>
);

export default GaelLogo;
