import Keycloak from "keycloak-js";

const keycloak = new Keycloak({
  realm: "JEI",
  url: process.env.REACT_APP_KEYCLOAK_ADDRESS || "https://identity.junior-entreprises.com",
  clientId: "kiwi-front",
  // "public-client": true,
});

export default keycloak;
