import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  ARRAY_ADD_NEW_LINE,
  ARRAY_DELETE_LINE,
} from "../../actions/actionTypes";
import api from "../../api";
import { setLineInArray, unsetLineInArray } from "../../actions/actionCreators";
import { getCurrentCardId, getGridAnswerId } from "../../selectors";
import { Etude, SagaPayloadLine } from "../../types/types";

function* addNewLine(
  addNewLineAction: {
    payload: {
      arrayId: number;
      etudeArray: Etude[]
    };
  }) {
  try {
    const gridAnswerId: number = yield select(getGridAnswerId);
    const cardAnswerId: number = yield select(getCurrentCardId);
    const arrayId = addNewLineAction.payload.arrayId;
    let addNewLineRequest: SagaPayloadLine;

    if (addNewLineAction.payload.etudeArray) {
      addNewLineRequest = yield call(
        api.get,
        `/grid_answers/${gridAnswerId}/card_answers/${cardAnswerId}/etudes/new`
      );
    } else {
      addNewLineRequest = yield call(
        api.get,
        `/grid_answers/${gridAnswerId}/field_answer/array/${arrayId}/lines/new`
      );
    }

    yield put(setLineInArray(arrayId, addNewLineRequest.data));
  } catch (error) {
    console.error("Error while adding new line to Array", error);
  }
}

function* deleteLineFromArray(deleteLineFromArrayAction: {
  payload: { arrayId: number; lineId: number; etudeArray: Array<any> };
}) {
  try {
    const gridAnswerId: number = yield select(getGridAnswerId);
    const cardAnswerId: number = yield select(getCurrentCardId);
    const lineId = deleteLineFromArrayAction.payload.lineId;
    const arrayId = deleteLineFromArrayAction.payload.arrayId;

    if (deleteLineFromArrayAction.payload.etudeArray) {
      yield call(
        api.delete,
        `/grid_answers/${gridAnswerId}/card_answers/${cardAnswerId}/etudes/${lineId}`
      );
    } else {
      yield call(
        api.delete,
        `/grid_answers/${gridAnswerId}/field_answer/array/lines/${lineId}`
      );
    }

    yield put(unsetLineInArray(arrayId, lineId));
  } catch (error) {
    console.error("Error while adding new line to Array", error);
  }
}

const exported = [
  takeLatest(ARRAY_DELETE_LINE as any, deleteLineFromArray),
  takeLatest(ARRAY_ADD_NEW_LINE as any, addNewLine),
];

export default exported;
