import {
  Button,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ZoomInOutlinedIcon from "@material-ui/icons/ZoomInOutlined";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  addNewLineToArray,
  deleteLineFromArray,
} from "../../actions/actionCreators";
import fieldConstructor from "../../forms/fieldConstructor";
import { Etude, GaelField } from "../../types/types";
import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EtudeUpload from "../EtudeUpload";

const useStyles = makeStyles((theme: Theme) => ({
  tableContainer: {
    width: "100%",
    overflowX: "auto",
    background: "transparent",
    borderColor: "0 !important",
    borderWidth: "0px !important",
    boxShadow: "none",
  },
  table: {
    minWidth: 700,
  },
  rightIcon: {
    marginLeft: "10px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  accordionContent: {
    display: "flow",
  },
  details: {
    alignItems: "center",
  },
  column: {
    flexBasis: "33.33%",
  },
}));

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

type DispatchProps = {
  deleteLine: typeof deleteLineFromArray;
  addNewLine: typeof addNewLineToArray;
};

type Props = {
  disabled: boolean;
  field: GaelField;
  placeholder: string;
  etude: Array<Etude>;
  form: string;
  pristine: boolean;
  invalid: boolean;
} & DispatchProps;

const ArrayFieldComponent = (props: Props) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  const columns = props.field.columns_names;
  const location = useLocation();
  const classes = useStyles();

  return (
    <>
      {props.etude ? (
        <React.Fragment>
          <Grid container>
            <Grid item className={classes.tableContainer}>
              <Table className={classes.table}>
                <TableBody>
                  {props.field.lines.length > 0 &&
                    props.field.lines.map((line, index) => {
                      return (
                        <StyledTableRow
                          key={`row-${line.id}`}
                          style={{ display: "flex" }}
                        >
                          <Accordion
                            className={classes.tableContainer}
                            expanded={expanded === `row-${line.id}`}
                            onChange={handleChange(`row-${line.id}`)}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={1}>
                                  {index + 1}
                                </Grid>
                                {columns
                                  .map(([id]) =>
                                    line.field_answers.find(
                                      (answer) => answer.field_id === id
                                    )
                                  )
                                  .filter((e) => e)
                                  .map((field) => {
                                    // console.log(field);
                                    return (
                                      <>
                                        {" "}
                                        {field && field.position < 3 && (
                                          <Grid item xs key={`cell-${field.id}`}>
                                            {fieldConstructor(field, props.disabled, props.form, props.pristine, props.invalid, true)}
                                          </Grid>
                                        )}
                                        {field && field.name === "Type" && field.content === "Déontologique"  && (
                                            <Grid item xs={2}>
                                              <Button
                                                  variant="outlined"
                                                  color="secondary"
                                                  component={Link}
                                                  to={`${location.pathname}/${line.id}`}
                                                  endIcon={<ZoomInOutlinedIcon />}
                                              >
                                                Auditer l'étude
                                              </Button>
                                            </Grid>
                                        )}
                                        {field && field.name === "Type" && field.content !== "Déontologique"  && (
                                            <Grid item xs={2}>
                                            </Grid>
                                        )}
                                        {field && field.name === "Type" && (
                                          <Grid item xs={1}>
                                          <Button
                                            aria-label="Delete"
                                            onClick={() =>
                                                props.deleteLine(
                                                    props.field.id,
                                                    line.id,
                                                    props.etude
                                                )
                                            }
                                            disabled={props.disabled}
                                            endIcon={<DeleteIcon />}
                                        ></Button>
                                          </Grid>)
                                        }
                                      </>
                                    );
                                  })}
                              </Grid>
                            </AccordionSummary>
                            <AccordionDetails className={classes.details}>
                              <Grid container spacing={3}>
                                {columns
                                  .map(([id]) =>
                                    line.field_answers.find(
                                      (answer) => answer.field_id === id
                                    )
                                  )
                                  .filter((e) => e)
                                  .map((field) => {
                                    // console.log(field);
                                    return (
                                      <>
                                        {field && field.position >= 3 && (
                                          <Grid
                                            item
                                            xs={6}
                                            spacing={4}
                                            key={`cell-${field.id}`}
                                          >
                                            {field.name}
                                            {fieldConstructor(
                                              field,
                                              props.disabled,
                                              props.form,
                                              props.pristine,
                                              props.invalid,
                                              true
                                            )}
                                          </Grid>
                                        )}
                                      </>
                                    );
                                  })}
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
          {/*<EtudeUpload field={props.field} />*/}
          <Button
            onClick={() => props.addNewLine(props.field.id, props.etude)}
            color="secondary"
            aria-label="add"
            disabled={props.disabled}
          >
            <AddIcon />
          </Button>
          {/* file input field */}
          {/* parse and addNewLine for each field */}
          {/* <Button */}
          {/*   onClick={() => { */}
          {/*     props.addNewLine(props.field.id, props.etude) */}
          {/*   } */}
          {/* } */}
          {/*   color="secondary" */}
          {/*   aria-label="add" */}
          {/*   disabled={props.disabled} */}
          {/* > */}
          {/*   Ajout par CSV */}
          {/*   <input type="file" hidden onChange={(event)=>csvToJson(event)} /> */}
          {/* </Button> */}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography>{props.placeholder}</Typography>
          <Grid container>
            <Grid item className={classes.tableContainer}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    {columns.map(([id, col]) => (
                      <TableCell key={`col-${id}`}>{col}</TableCell>
                    ))}
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.field.lines.length > 0 &&
                    props.field.lines.map((line, index) => {
                      return (
                        <TableRow key={`row-${line.id}`}>
                          <TableCell>{index + 1}</TableCell>
                          {columns
                            .map(([id]) =>
                              line.field_answers.find(
                                (answer) => answer.field_id === id
                              )
                            )
                            .filter((e) => e)
                            .map((field) => {
                              if (!field) {
                                return;
                              }
                              return (
                                <>
                                  <TableCell key={`cell-${field.id}`}>
                                    {fieldConstructor(
                                      field,
                                      props.disabled,
                                      props.form,
                                      props.pristine,
                                      props.invalid,
                                      true
                                    )}
                                  </TableCell>
                                </>
                              );
                            })}
                          <TableCell
                            style={{
                              display: "flex",
                              flexWrap: "nowrap",
                              alignItems: "center",
                            }}
                          >
                            <IconButton
                              aria-label="Delete"
                              onClick={() =>
                                props.deleteLine(
                                  props.field.id,
                                  line.id,
                                  props.etude
                                )
                              }
                              disabled={props.disabled}
                              className={classes.rightIcon}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
          <Grid container justify={"flex-end"} style={{ marginTop: 10 }}>
            <Grid item>
              <Button
                onClick={() => props.addNewLine(props.field.id, props.etude)}
                color="secondary"
                aria-label="add"
                disabled={props.disabled}
              >
                <AddIcon />
                Ajouter une nouvelle ligne
              </Button>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </>
  );
};

const mapDispatchToProps = {
  addNewLine: addNewLineToArray,
  deleteLine: deleteLineFromArray,
};

export default connect(null, mapDispatchToProps)(ArrayFieldComponent);
