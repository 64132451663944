import React from "react";
import { Field, submit } from "redux-form";
import moment from "moment/moment";

import { DatePickerComponent } from "./Date";
import { Typography, Grid, FormHelperText } from "@material-ui/core";
import { GaelField } from "../../types/types";
import { BaseFieldProps } from "./Document";
import { Dispatch } from "redux";
import { connect } from "react-redux";

type Props = {
  field: GaelField;
  placeholder: string;
  required: boolean;
  disabled: boolean;
  form: string;
  pristine: boolean;
  invalid: boolean;
  formName: string;
  isPristine: boolean;
  dispatch: Dispatch;
};

const DurationField = (props: Props & BaseFieldProps) => {
  const { input, field, placeholder, required, meta, disabled } = props;
  const helperText = meta.error ? meta.error : field.hint;
  return (
    <div>
      <Typography variant="caption">
        {placeholder}
        {required ? " *" : ""}
      </Typography>
      <Grid
        container
        // spacing={16}
      >
        <Grid item xs>
          <DatePickerComponent
            disabled={disabled}
            required={required}
            value={
              input?.value?.content_duration_start
                ? moment(input.value.content_duration_start)
                : null
            }
            onChange={(value: any) => {
              input.onChange({
                ...input.value,
                content_duration_start: value
                  ? value.format("YYYY-MM-DD")
                  : value,
              });
              if (!props.invalid) {
                setTimeout(() => props.dispatch(submit(props.formName)), 100);
              }
            }}
            minDate={field.restrictions.start_minimum}
            maxDate={field.restrictions.start_maximum}
            error={meta.error}
          />
        </Grid>
        <Grid item xs>
          <DatePickerComponent
            disabled={disabled}
            required={required}
            value={
              input.value && input.value.content_duration_end
                ? moment(input.value.content_duration_end)
                : null
            }
            onChange={(value: any) => {
              input.onChange({
                ...input.value,
                content_duration_end: value
                  ? value.format("YYYY-MM-DD")
                  : value,
              });
              if (!props.invalid) {
                setTimeout(() => props.dispatch(submit(props.formName)), 100);
              }
            }}
            minDate={field.restrictions.end_minimum}
            maxDate={field.restrictions.end_maximum}
            error={meta.error}
          />
        </Grid>
      </Grid>
      {helperText && (
        <FormHelperText error={meta.error}>{helperText}</FormHelperText>
      )}
    </div>
  );
};

const DurationFieldConnected = connect()(DurationField);

type PropsComponent = {
  field: GaelField;
  component?: any;
  options?: any[];
  inTable: boolean;
  helperText?: string;
  placeholder: string;
  form: string;
  pristine: boolean;
  invalid: boolean;
};

const DurationFieldComponent = (props: PropsComponent) => (
  <Field
    component={DurationFieldConnected}
    formName={props.form}
    isPristine={props.pristine}
    {...props}
  />
);

export default DurationFieldComponent;
