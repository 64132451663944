import _isEqual from "lodash/isEqual";
import _reduce from "lodash/reduce";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { loadGridAnswer, setBlocAnswer } from "../../actions/actionCreators";
import { BLOC_ANSWER_SAVE } from "../../actions/actionTypes";
import api from "../../api";
import { getCurrentCardId, getGridAnswerId } from "../../selectors";
import { EtudesBloc, GaelField, Line, SagaPayload } from "../../types/types";

function* retrieveGridAnswer() {
  try {
    const gridAnswerId: number = yield select(getGridAnswerId);
    const url = `/grid_answers/${gridAnswerId}`;
    const gridAnswerRequest: SagaPayload = yield call(api.get, url);
    yield put(loadGridAnswer(gridAnswerRequest.data));
  } catch (err) {
    console.error("Failed to retrieve GridAnswer", err);
  }
}

function* saveEtude(etude: EtudesBloc) {
  try {
    const gridAnswerId: number = yield select(getGridAnswerId);
    const cardAnswerId: number = yield select(getCurrentCardId);
    const url = `/grid_answers/${gridAnswerId}/card_answers/${cardAnswerId}/etudes/${etude.id}`;
    yield call(api.patch, url, {
      etude: {
        field_answers: etude.field_answers,
      },
    });
  } catch (err) {
    console.error("Error while patching etude", err);
  }
}

function* saveBlocAnswer(saveBlocAnswerAction: any) {

  try {
    const { formProps = {}, answers: oldFields = {} } = saveBlocAnswerAction.payload;
    const { blocFields: newFields, form: formId } = formProps;
    // console.log(saveBlocAnswerAction, oldFields, newFields);
    const blocAnswerId = formId.replace("form-", "");

    // Retrieve all the registered fields in the form, even the one in lines of Array
    const allNewFields = [
      ...newFields,
      // We're going through the Array to retrieve the cell fields
      ...newFields
        .filter((f: GaelField) =>
          ["Field::Array", "Field::ArrayEtude"].includes(f.type)
        )
        .reduce(
          (acc: Array<GaelField>, f: any) => [
            ...acc,
            ...f.lines.reduce(
              (acc: Array<Line>, l: Line) => [...acc, ...l.field_answers],
              []
            ),
          ],
          []
        ),
    ];

    // fields with their formatted answer
    const fieldAnswersFormatted: Array<GaelField> = [
      // For all the inputs/fields in the current form, we map the value to the registered field
      ..._reduce(
        oldFields,
        (acc: GaelField[], answer, key) => {
          const fieldId = parseInt(key.replace("field-", ""), 10);

          const newField = allNewFields.find((field) => field.id === fieldId);

          // We only want to update a field value if it changed
          if (
            !newField ||
            _isEqual(newField.content, answer) ||
            (newField.type === "Field::Duration" &&
              newField.content[0] === answer.content_duration_start &&
              newField.content[1] === answer.content_duration_end)
          ) {
            return acc;
          }

          switch (newField.type) {
            case "Field::Duration":
              return [
                ...acc,
                {
                  id: fieldId,
                  ...answer,
                },
              ];
            case "Field::Document":
              return [
                ...acc,
                {
                  id: fieldId,
                  content: answer,
                  isDocument: true,
                },
              ];
            case "Field::Checkbox":
            case "Field::Select":
            case "Field::Text":
            case "Field::Textarea":
            case "Field::Number":
            case "Field::Date":
            default:
              return [
                ...acc,
                {
                  id: fieldId,
                  content: answer,
                },
              ];
          }
        },
        []
      ),
      ...allNewFields.filter((field) => field.type === "Field::Array"),
      ...allNewFields.filter((f) => f.type === "Field::ArrayEtude"),
    ];

    const fieldAnswerToRemove: Array<number> = [];
    const etudesAnswers: Array<any> = [];

    const newFieldAnswers = fieldAnswersFormatted.reduce(
      (acc: Array<GaelField>, f: any) => {
        if (f.type === "Field::Array") {
          return [
            ...acc,
            {
              ...f,
              lines: f.lines.map((l: Line) => ({
                id: l.id,
                field_answers: l.field_answers.map((lfa) => {
                  const trueFa = fieldAnswersFormatted.find(
                    (fa) => fa.id === lfa.id
                  );
                  if (trueFa) {
                    fieldAnswerToRemove.push(trueFa.id);
                    return trueFa;
                  }
                  return lfa;
                }),
              })),
            },
          ];
        } else if (f.type === "Field::ArrayEtude") {
          f.etudes = [
            ...f.lines.map((e: Line) => ({
              ...e,
              field_answers: e.field_answers.map((efa) => {
                const completeEfa = fieldAnswersFormatted.find(
                  (fa) => fa.id === efa.id
                );
                if (completeEfa) {
                  fieldAnswerToRemove.push(completeEfa.id);
                  return completeEfa;
                }
                return efa;
              }),
            })),
          ];
          etudesAnswers.push(...f.etudes);
          return acc;
        } else return [...acc, f];
      },
      []
    );

    // We remove the fieldAnswers that correspond to cells in Array
    const fieldAnswers: any = newFieldAnswers.reduce(
      (acc, field) =>
        // @ts-ignore
        fieldAnswerToRemove.includes(field.id) ? acc : [...acc, field],
      []
    );

    const gridAnswerId: number = yield select(getGridAnswerId);
    const url = `/grid_answers/${gridAnswerId}/bloc_answers/${blocAnswerId}`;

    // We need to separate the document fields from the other field
    const { documentFields, nonDocumentFields } = fieldAnswers.reduce(
      (acc: Array<any>, field: any) => {
        if (field.isDocument) {
          return {
            ...acc,
            documentFields: [
              ...(acc as any).documentFields,
              {
                id: field.id,
                content: field.content,
              },
            ],
          };
        } else {
          return {
            ...acc,
            nonDocumentFields: [...(acc as any).nonDocumentFields, field],
          };
        }
      },
      { documentFields: [], nonDocumentFields: [] }
    );

    if (etudesAnswers.length > 0) {
      // We need to save each ArrayEtude row by itself
      yield all(etudesAnswers.map((e) => call(saveEtude, e)));

      yield call(retrieveGridAnswer);
    }

    if (documentFields.length) {
      try {
        const documentFieldRequests: Array<SagaPayload> = yield all(
          documentFields
            // ensure we don't try to update file field with string aws address
            .filter((field: GaelField) => field.content instanceof File)
            .map(function* (field: GaelField): Generator {
            const formData = new FormData();
            formData.append(`bloc_answer[field_answers[]][id]`, field.id + "");
            formData.append(
              `bloc_answer[field_answers[]][content]`,
              field.content
            );
            return yield call(api.patch, url, formData);
          })
        );
        if (!nonDocumentFields.length) {
          yield put(
            setBlocAnswer(
              documentFieldRequests[documentFieldRequests.length - 1].data
            )
          );
        }
      } catch (error) {
        console.error("Couldn't upload file(s).", error);
      }
    }

    if (nonDocumentFields.length) {
      const saveBlocAnswerRequest: SagaPayload = yield call(api.patch, url, {
        bloc_answer: {
          field_answers: [...nonDocumentFields],
        },
      });
      yield put(setBlocAnswer(saveBlocAnswerRequest.data));
    }

    saveBlocAnswerAction.resolve();
  } catch (error) {
    console.error(error);
    saveBlocAnswerAction.reject();
  }
}

const exported = [takeLatest(BLOC_ANSWER_SAVE, saveBlocAnswer)];

export default exported;
