import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Typography
} from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Field, submit } from "redux-form";
import useSubmiter from "../../api/submiter";
import { GaelField } from "../../types/types";
import { BaseFieldProps } from "./Document";

type Props = {
  required: boolean;
  placeholder: string;
  field: GaelField;
  disabled: boolean;
  form: string;
  pristine: boolean;
  invalid: boolean;
  dispatch: Dispatch;
};

const CheckboxComponent = (props: Props & BaseFieldProps) => {
  const {
    placeholder,
    required,
    field: {
      field_id,
      restrictions: { values },
      hint,
    },
    input,
    meta,
    disabled,
  } = props;

  const helperText = meta.error && meta.touched ? meta.error : hint;
  return values && Array.isArray(values) ? (
    <FormControl
      component="fieldset"
      fullWidth
      required={required}
      error={meta.touched && meta.error}
    >
      <FormLabel component="legend">{placeholder}</FormLabel>
      <FormGroup>
        {values.map((item) => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  {...input}
                  disabled={disabled}
                  onChange={(event) => {
                    const v = [...input.value];
                    const newVal = event.target.checked
                      ? [...v, item]
                      : v.filter((e) => e !== item);
                    props.input.onChange(newVal);
                  }}
                  onBlur={() => input.onBlur(input.value)}
                  value={item}
                  checked={input.value && input.value.includes(item)}
                />
              }
              label={item}
            />
          )
        })}
      </FormGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  ) : (
    <Typography style={{ backgroundColor: 'red' }}>
      Erreur lors de la déclaration du champs (Checkbox) field_id: {field_id}
    </Typography>
  );
};

const CheckboxFieldComponent = (props: Props) => {
  const submit = useSubmiter(props.form);
  return (
    <Field
      component={CheckboxComponent}
      onChange={() => {
        if (!props.invalid) {
          submit();
        }
      }}
      {...props}
    />
  );
}
export default connect()(CheckboxFieldComponent);
