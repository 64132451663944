import { Button } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { submitGridAnswer } from "../actions/actionCreators";
import { AppState } from "../store/configureStore";

type Props = {
  canSubmit: boolean;
  submitGridAnswerDispatcher: () => void;
};

const SubmitButton = ({ canSubmit, submitGridAnswerDispatcher }: Props) => {
  return canSubmit ? (
    <Button variant="contained" onClick={submitGridAnswerDispatcher}>
      Soumettre l 'integralité du formulaire
    </Button>
  ) : null;
};

const mapStateToProps = (state: AppState) => ({
  canSubmit: state.currentUser?.permissions?.CAN_SUBMIT ?? false,
});

const mapDispatchToProps = {
  submitGridAnswerDispatcher: submitGridAnswer,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmitButton);
