import { makeStyles } from "@material-ui/styles";
import { useKeycloak } from "@react-keycloak/web";
import React, {useEffect, useRef} from "react";
import { connect } from "react-redux";
import { Route, useHistory, useLocation } from "react-router-dom";
import { useBindGlobal } from "../api/globals";
import { UserState } from "../reducers/currentUser";
import { AppState, loadGrid } from "../store/configureStore";
import {StateGridAnswer} from "../types/types";
import { makePersistor } from "../utils/binding";
import Content from "./Content";
import Error from './Error';
import AppBar from './layout/AppBar';
import LoadingComponent from "./LoadingComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100vh",
    zIndex: 1,
    overflow: "hidden",
  },
}));

type GridViewProps = {
  user: UserState | undefined;
  gridAnswer: StateGridAnswer;
};

const Loading = () => {
  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <LoadingComponent loading text="Chargement des grilles !" />
    </div>
  );
};

export const { get: getAudit, set: setAudit } = makePersistor<{ auditId: number }>("lastAudit");

const getAuditId = () => {
  const location = new URL(window.location.href);
  let auditId = location.searchParams.get("auditId");
  if (auditId) {
    setAudit({ auditId: +auditId });
    return +auditId;
  }
  const storedId = getAudit();
  if (storedId?.auditId) {
    return storedId.auditId;
  }
  return undefined;
}


const GridView = (props: GridViewProps) => {
  const classes = useStyles();
  const bind = useBindGlobal();
  const { user, gridAnswer } = props;
  const { keycloak, initialized } = useKeycloak();
  const history = useHistory();
  const location = useLocation();
  const [loaded, setLoaded] = React.useState(false);
  useEffect(() => {
    if (keycloak.token) {
      const auditId = getAuditId();
      if (auditId) {
        loadGrid(+auditId, keycloak.token,loaded).then(() => {
            setLoaded(true);
        });
        bind();
      } else {
        history.push('/error');
      }
    }
  }, [bind, keycloak.token,loaded]);

  if (!initialized) {
    return <Loading />;
  }

  if (!keycloak.authenticated) {
    keycloak.login();
  }

  if (location.pathname === "/error") {
    return <Error />;
  }

  if (!user || (user && Object.keys(user).length === 0)) {
    return <Loading />;
  }

  if (
    !gridAnswer ||
    (gridAnswer && Object.keys(gridAnswer).length === 0)
  ) {
    return <Loading />;
  }



  return (
    user?.id && user.permissions && gridAnswer.id ? (
      <div className={classes.root}>
        <AppBar />
        <Route path={"/error"} component={Error} />
        <Route path={"/*"} component={Content} />
      </div>
    ) : (
      <Loading />
    )
  );
};
const mapStateToProps = (state: AppState) => {
  return {
    user: state.currentUser,
    gridAnswer: state.gridAnswer,
  };
};

export default connect(mapStateToProps)(GridView);
