import React from "react";
import { memoize } from "lodash";

import BasicFieldComponent from "../components/fields/Basic";
import SelectFieldComponent from "../components/fields/Select";
import DateFieldComponent from "../components/fields/Date";
import DurationFieldComponent from "../components/fields/Duration";
import CheckboxFieldComponent from "../components/fields/Checkbox";
import DocumentFieldComponent from "../components/fields/Document";
import ArrayFieldComponent from "../components/fields/Array";

import {
  required,
  maxLength,
  minValue,
  maxValue,
  number,
  count,
} from "./validators";
import { Etude, GaelField } from "../types/types";

const defineValidations = (field: GaelField) => {
  const validations = [];
  if (field.required) {
    validations.push(required);
  }

  if (field.restrictions) {
    switch (field.type) {
      case "Field::Text":
      case "Field::Textarea":
        // kinda strange.
        validations.push(maxLength((field.restrictions as unknown) as number));
        break;
      case "Field::Number":
        validations.push(number);
        field.restrictions.minimum !== null &&
          validations.push(minValue(field.restrictions.minimum!));
        field.restrictions.maximum !== null &&
          validations.push(maxValue(field.restrictions.maximum!));
        break;
      case "Field::Checkbox":
        field.restrictions.count !== null &&
          validations.push(count(field.restrictions.count!));
        break;
    }
  }
  return validations;
};

const memoizedDefineValidations = memoize(
  defineValidations,
  (field) => field.id
);

type FieldProps = {
  type?: string;
  label?: string;
  name: string;
  placeholder: string;
  helperText?: string;
  disabled: boolean;
  required: boolean;
  validate: any;
  options: any;
  inTable: boolean;
  form: string;
  pristine: boolean;
  invalid: boolean;
  field?: GaelField;
  etude?: boolean;
};

const FieldConstructor = (
  field: GaelField,
  disabled: boolean,
  form: string,
  pristine: boolean,
  invalid: boolean,
  inTable?: boolean
) => {
  const name = `field-${field.id}`;
  const { name: placeholder, hint, type, required } = field;

  const helperText = hint === "" ? undefined : hint;

  const validate = memoizedDefineValidations(field);

  const fieldProps: FieldProps = {
    name,
    placeholder: !inTable ? placeholder : "",
    helperText: !inTable ? helperText : "",
    disabled: disabled,
    required,
    validate,
    inTable: inTable ? inTable : false,
    label: !inTable ? field.name : "",
    options: {},
    pristine,
    invalid,
    form: form,
  };

  if (type === "Field::Text") {
    return (
      <BasicFieldComponent
        key={field.id}
        {...fieldProps}
        type="text"
        helperText={fieldProps.helperText || ""}
      />
    );
  }

  if (type === "Field::Number") {
    return (
      <BasicFieldComponent
        key={field.id}
        {...fieldProps}
        type="number"
        helperText={fieldProps.helperText || ""}
      />
    );
  }

  if (type === "Field::Textarea") {
    return (
      <BasicFieldComponent
        key={field.id}
        {...fieldProps}
        type="text"
        rows={inTable ? 2 : 4}
        multiline
        helperText={fieldProps.helperText || ""}
      />
    );
  }

  if (type === "Field::Date") {
    return <DateFieldComponent key={field.id} {...fieldProps} field={field} />;
  }

  if (type === "Field::Select") {
    fieldProps.options = field.restrictions;
    return (
      <SelectFieldComponent
        key={field.id}
        {...fieldProps}
        helperText={fieldProps.helperText || ""}
      />
    );
  }

  if (type === "Field::Duration") {
    delete fieldProps.label;
    return (
      <DurationFieldComponent key={field.id} {...fieldProps} field={field} />
    );
  }

  if (type === "Field::Checkbox") {
    delete fieldProps.label;
    return (
      <CheckboxFieldComponent key={field.id} {...fieldProps} field={field} />
    );
  }

  if (type === "Field::Document") {
    return (
      <DocumentFieldComponent key={field.id} {...fieldProps} field={field} />
    );
  }

  if (type === "Field::Array" || type === "Field::ArrayEtude") {
    return (
      <ArrayFieldComponent
        {...fieldProps}
        field={field}
        etude={((type === "Field::ArrayEtude") as unknown) as Array<Etude>}
      />
    );
  }
  return null;
};

export default FieldConstructor;
