export interface GaelEnv {
  gaelURL: string;
}

const getEnv = (): GaelEnv => {
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
  if (isDev) {
    const env = {
      // gaelURL: "https://gael.junior-entreprises.com/api/v1",
      gaelURL: "http://localhost:3000/api",
    //  gaelURL: "https://gael-middle.beta.junior-entreprises.com/proxy",
      //gaelURL: "https://cors-anywhere.herokuapp.com/http://gael.junior-entreprises.com/api/v1/",
    };
    return env;
  } else {
    const env = {
       // gaelURL: "https://gael-middle.beta.junior-entreprises.com/proxy",
        gaelURL: "https://gael.junior-entreprises.com/api/v1",
      // gaelURL: "https://gael.junior-entreprises.com/api/v1",
    };
    return env;
  }
};

export default getEnv();
