import { Grid, Theme, WithStyles, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { setCurrentCard } from "../../actions/actionCreators";
import { getCurrentCard, getCurrentCardFromPath } from "../../selectors";
import BlocList from "../BlocList";
import Breadcrumb from "../layout/Breadcrumb";
import SubmitButton from "../SubmitButton";
import Tree from "../Tree";
import { CardAnswer } from "../../types/types";
import { AppState } from "../../store/configureStore";

const styles = (theme: Theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.background.default,
    padding: 20,
    height: "100%",
    flexWrap: "nowrap" as "nowrap",
    flexGrow: 1,
    minWidth: 0,
  },
  content: {
    overflow: "auto",
    height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
    [theme.breakpoints.up("sm")]: {
      // TODO
      // height: `calc(100% - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`
    },
  },
  toolbar: theme.mixins.toolbar,
});

interface ContentProps extends WithStyles<typeof styles> {
  currentCard: CardAnswer;
  currentCardFromPath: number;
  setCurrentCard: (currentCardFromPath: number) => void;
}

class Content extends Component<ContentProps & RouteComponentProps> {
  componentDidMount() {
    const { currentCard, currentCardFromPath } = this.props;
    if (!currentCard && currentCardFromPath) {
      this.props.setCurrentCard(currentCardFromPath);
    }
  }

  componentDidUpdate() {
    const { currentCard, currentCardFromPath } = this.props;
    // console.log(currentCard);
    // console.log(currentCardFromPath);
    if (
      (currentCard && !currentCardFromPath) ||
      (currentCardFromPath && !currentCard) ||
      (currentCardFromPath &&
        currentCard &&
        currentCard.id !== currentCardFromPath)
    ) {
      this.props.setCurrentCard(this.props.currentCardFromPath);
    }
  }

  render() {
    const { currentCard, match, classes, currentCardFromPath } = this.props;
    if (currentCard === null && match.url !== "/") {
      return <Redirect to={"/"} />;
    }

    // console.log(currentCard);
    // console.log(currentCardFromPath);
    // console.log(match);

    const currentCardIsNode =
      !currentCard || (currentCard && currentCard.cards.length > 0);

    // console.log(currentCardIsNode);
    return (
      <React.Fragment>
        <Grid container direction="column" spacing={8} className={classes.root}>
          <Grid item className={classes.toolbar} />
          <Grid item>
            <Grid container justify={"space-between"}>
              <Grid item>
                <Breadcrumb url={match.url} />
              </Grid>
              {match.url === "/" && (
                <Grid item>
                  <SubmitButton />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item className={classes.content}>
            {currentCardIsNode ? <Tree /> : <BlocList />}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: ContentProps) => ({
  currentCardFromPath: getCurrentCardFromPath(state, ownProps),
  currentCard: getCurrentCard(state, ownProps),
});

const mapDispatchToProps = {
  setCurrentCard,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(withStyles(styles)(Content));
