import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { LightDarkToggle } from "@tsanyqudsi/react-light-dark-toggle";
import { ThemeSettings, themeState, THEME_TOKEN } from "../../store/atom/atomTheme";

export function useDarkmode(): [ThemeSettings, (value: boolean) => void] {
  const [darkmode, setDarkmode] = useRecoilState(themeState);

  useEffect(() => {
    const textSettings = localStorage.getItem(THEME_TOKEN);
    if (textSettings) {
      const settings: ThemeSettings = JSON.parse(textSettings);
      setDarkmode({ dark: settings.dark });
    }
  }, [setDarkmode]);

  const _setDarkmode = (value: boolean) => {
    const settings = { dark: value };
    setDarkmode(settings);
    localStorage.setItem(THEME_TOKEN, JSON.stringify(settings));
  };
  return [darkmode, _setDarkmode];
}

const DarkModeToggle = () => {
  const [theme, setDarkmode] = useDarkmode();
  return <LightDarkToggle onToggle={e => setDarkmode(!e)} isLight={!theme.dark} />;
};

export default DarkModeToggle;
