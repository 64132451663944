import { CURRENT_USER_LOAD } from "../actions/actionTypes";
import { CURRENT_USER_SET_PERMISSIONS } from "../actions/actionTypes";

export type UserState = {
  id: number;
  email: string;
  is_auditor: boolean;
  is_debriefer: boolean;
  is_president: boolean;
  permissions: {
    CAN_WRITE: boolean;
    CAN_READ: boolean;
    CAN_SUBMIT: boolean;
  };
  Authorization: string;
};

const currentUser = (state: UserState | {} = {}, action: any) => {
  switch (action.type) {
    case CURRENT_USER_LOAD:
      return {
        ...state,
        ...action.payload,
      };
    case CURRENT_USER_SET_PERMISSIONS:
      return {
        ...state,
        permissions: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default currentUser;
