import {
  GRID_ANSWER_SET,
  CURRENT_CARD_SET,
  ARRAY_LINE_SET,
  ARRAY_LINE_UNSET,
  CARD_ANSWER_SUCCESS,
  CARD_ANSWER_FAILURE,
  CARD_ANSWER_REQUEST_START,
  CARD_ANSWER_REQUEST_END,
  BLOC_ANSWER_SET,
} from "../actions/actionTypes";

const setCurrentCardId = (state, action) => ({
  ...state,
  currentCardId: action.payload,
});

const setGridAnswer = (state, action) => ({
  ...state,
  ...action.payload,
});

const setArrayLine = (state, action) => ({
  ...state,
  cardAnswerSet: {
    ...state.cardAnswerSet,
    [state.currentCardId]: {
      ...state.cardAnswerSet[state.currentCardId],
      blocs: [
        ...state.cardAnswerSet[state.currentCardId].blocs.map((bloc) => {
          return {
            ...bloc,
            fields: bloc.fields.map((field) => {
              if (field.id === action.payload.arrayId) {
                return {
                  ...field,
                  lines: [...field.lines, action.payload.line],
                };
              } else {
                return field;
              }
            }),
          };
        }),
      ],
    },
  },
});

const unsetArrayLine = (state, action) => ({
  ...state,
  cardAnswerSet: {
    ...state.cardAnswerSet,
    [state.currentCardId]: {
      ...state.cardAnswerSet[state.currentCardId],
      blocs: [
        ...state.cardAnswerSet[state.currentCardId].blocs.map((bloc) => {
          return {
            ...bloc,
            fields: bloc.fields.map((field) => {
              if (field.id === action.payload.arrayId) {
                return {
                  ...field,
                  lines: field.lines.reduce(
                    (acc, line) =>
                      line.id === action.payload.lineId ? acc : [...acc, line],
                    []
                  ),
                };
              } else {
                return field;
              }
            }),
          };
        }),
      ],
    },
  },
});

const setCardAnswerRequestStatus = (state, action) => ({
  ...state,
  fetchingCardAnswer: action.fetching,
});

const setCardAnswer = (state, action) => ({
  ...state,
  cardAnswerSet: {
    ...state.cardAnswerSet,
    [action.card.id]: {
      ...action.card,
      path: state.cardAnswerSet[action.card.id].path,
    },
  },
});

const fetchingCardAnswerError = (state, action) => ({
  ...state,
  error: action.error,
});

const setBlocAnswer = (state, action) => {
  const currentCardId = state.currentCardId;
  const currentCard = { ...state.cardAnswerSet[currentCardId] };

  return setCardAnswer(state, {
    card: {
      ...currentCard,
      blocs: currentCard.blocs.map((bloc) => {
        if (bloc.id === action.blocAnswer.id) return action.blocAnswer;
        return bloc;
      }),
    },
  });
};

const gridAnswer = (state = {}, action) => {
  switch (action.type) {
    case CURRENT_CARD_SET:
      return setCurrentCardId(state, action);
    case GRID_ANSWER_SET:
      return setGridAnswer(state, action);
    case ARRAY_LINE_SET:
      return setArrayLine(state, action);
    case ARRAY_LINE_UNSET:
      return unsetArrayLine(state, action);
    case CARD_ANSWER_REQUEST_START:
    case CARD_ANSWER_REQUEST_END:
      return setCardAnswerRequestStatus(state, action);
    case CARD_ANSWER_SUCCESS:
      return setCardAnswer(state, action);
    case CARD_ANSWER_FAILURE:
      return fetchingCardAnswerError(state, action);
    case BLOC_ANSWER_SET:
      return setBlocAnswer(state, action);
    default:
      return state;
  }
};

export default gridAnswer;
