import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
// import App from './App';
import reportWebVitals from "./reportWebVitals";
// import MomentUtils from '@material-ui/pickers/utils/moment-utils'
// import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider'
import moment from "moment";
import { RecoilRoot } from "recoil";
import App from './App';

moment.locale("fr");

// Used for the internal routing in the React app
// eslint-disable-next-line no-restricted-globals
const basename = location.pathname.split("/").splice(0, 3).join("/"); // 0 to 3 because leading '/' create empty '' on split

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
