import { atom, SetterOrUpdater, useSetRecoilState } from "recoil";

type AlertState = {
    message: string;
    validateAction?: (() => void) | (() => Promise<any>);
}  | undefined;

export const alertAtom = atom<AlertState>({
    key: 'alert',
    default: undefined,
});



export const clearAlert = (setter: SetterOrUpdater<AlertState>) => setter(undefined);


export const useAlert = () => {
    const setter = useSetRecoilState(alertAtom);
    return (message: string, validateAction: () => void) => {
        setter({ message, validateAction });
    }
}
