import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
// import App from './App';
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import MomentUtils from "@date-io/moment";
// import MomentUtils from '@material-ui/pickers/utils/moment-utils'
// import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider'
import moment from "moment";
import store from "./store/configureStore";
import GridView from "./components/GridView";
import { createMuiTheme, CssBaseline, ThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { RecoilRoot } from "recoil";
import Message from "./components/Snackbar/Snackbar";
import keycloak from "./keycloak";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { useDarkmode } from "./components/DarkModeToggle/DarkModeToggle";
import RecoilNexus from "recoil-nexus";

const makeTheme = (dark: boolean) => createMuiTheme({
  palette: {
    type: dark ? "dark" : "light",
    primary: {
      main: "rgba(183, 26, 81, 1)",
    },
  },
  typography: {
    //        fontFamily: 'DIN',
  },
  overrides: {
    MuiCard: {
      root: {
        overflow: "hidden",
        margin: "2rem 0px",
        boxShadow: "rgba(0, 0, 0, 0.3) 0px 8px 40px -12px !important",
        borderRadius: "1rem !important",
      },
    },
  },
});

export default function App() {
  
  const [themeSettings] = useDarkmode();
  const builtTheme = makeTheme(themeSettings.dark);
  
  return (
    <ThemeProvider theme={builtTheme}>
      <MuiPickersUtilsProvider
        utils={MomentUtils}
        libInstance={moment}
        locale="fr"
      >
        <CssBaseline />
        <RecoilNexus />
        <Provider store={store}>
          <Message />
          <ReactKeycloakProvider
            initOptions={{ onLoad: 'check-sso' }}
            authClient={keycloak}>
            <BrowserRouter>
              <GridView />
            </BrowserRouter>
          </ReactKeycloakProvider>
        </Provider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}