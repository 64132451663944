import { List, ListSubheader } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../store/configureStore";

import CardMenuItem from "./CardMenuItem";
import { CardAnswer } from "../../../types/types";

const SideMenuHeader = (): React.ReactElement<typeof ListSubheader> => (
  <ListSubheader>Arborescence</ListSubheader>
);

type SideMenuProps = {
  cards: Array<CardAnswer>;
};

const SideMenu = ({ cards }: SideMenuProps) => {
  return (
    <>
      <List subheader={<SideMenuHeader />}>
        {cards &&
          cards.map((card) => (
            <CardMenuItem card={card} level={0} key={card.id} />
          ))}
      </List>
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    cards: state.gridAnswer.card_answers,
  };
};

export default connect(mapStateToProps)(SideMenu);
