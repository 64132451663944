import { Typography, Link } from "@material-ui/core";
import React from 'react';

const kiwiUrl = "https://kiwix.junior-entreprises.com"

export default function Error() {


    return (
        <div style={{ height: '100vh', width: '100vw', display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="h3">
                Erreur de chargement, veuillez recharger la page depuis {" "}
                <Link href={kiwiUrl}>
                    Kiwi X
                </Link>
            </Typography>

        </div>
    )
}