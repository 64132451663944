import { Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { InjectedFormProps, reduxForm } from "redux-form";
import { saveBlocAnswer } from "../../actions/actionCreators";
import fieldConstructor from "../../forms/fieldConstructor";
import { CardBloc, GaelField } from "../../types/types";

export interface BlockFormProps {
  blocFields: Array<GaelField>;
  canWrite: boolean;
  onSubmit: any;
}

const BlocFormView = ({
  handleSubmit,
  blocFields,
  canWrite,
  pristine,
  submitting,
  invalid,
  ...rest
}: BlockFormProps & InjectedFormProps<{}, BlockFormProps>) => {
  // console.log(rest.form)
  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" spacing={2}>
        {blocFields.map((f: GaelField) => (
          <Grid item key={f.id}>
            {fieldConstructor(f, !canWrite, rest.form, pristine, invalid)}
          </Grid>
        ))}
        {/*canWrite && (
          <Grid item style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={pristine || submitting || invalid}
            >
              Enregistrer
            </Button>
          </Grid>
        )*/}
      </Grid>
    </form>
  );
};

const BlocForm = reduxForm<any, BlockFormProps>({})(BlocFormView);

type BlocProps = {
  bloc: CardBloc;
  canWrite: boolean;
  saveBlocAnswerDispatcher: any;
};

const Bloc = ({ bloc, canWrite, saveBlocAnswerDispatcher }: BlocProps) => {
  const initialValues = bloc.fields.reduce((acc: any, field: any) => {
    switch (field.type) {
      case "Field::ArrayEtude":
      case "Field::Array":
        const arrayFields = field.lines.reduce((acc: any, line: any) => {
          const lineFields = line.field_answers.reduce(
            (acc: any, fieldAnswer: any) => ({
              ...acc,
              [`field-${fieldAnswer.id}`]: fieldAnswer.content,
            }),
            {}
          );

          return {
            ...acc,
            ...lineFields,
          };
        }, {});

        return {
          ...acc,
          ...arrayFields,
        };
      case "Field::Duration":
        return {
          ...acc,
          [`field-${field.id}`]: {
            content_duration_start: field.content[0],
            content_duration_end: field.content[1],
          },
        };

      case "Field::Number":
        return {
          ...acc,
          [`field-${field.id}`]:
            field.content === null ? null : field.content.toString(),
        };
      default:
        return {
          ...acc,
          [`field-${field.id}`]: field.content,
        };
    }
  }, {});

  return (
    <Paper
      style={{
        padding: 20,
      }}
    >
      <Grid container direction={"column"} spacing={8}>
        <Grid item>
          <Typography variant="h2">{bloc.name}</Typography>
        </Grid>
        {bloc.description && (
          <Grid item>
            <Typography variant="h3">{bloc.description}</Typography>
          </Grid>
        )}
        <Grid item>
          <BlocForm
            form={`form-${bloc.id}`}
            blocFields={bloc.fields}
            canWrite={canWrite}
            onSubmit={(...args: any) => {
              return new Promise((resolve, reject) =>
                saveBlocAnswerDispatcher(...args, resolve, reject)
              );
            }}
            initialValues={initialValues}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

const mapDispatchToProps = {
  saveBlocAnswerDispatcher: saveBlocAnswer,
};

export default connect(null, mapDispatchToProps)(Bloc);
