import { combineReducers } from "redux";
import { reducer as form } from "redux-form";

import currentUser from "./currentUser";
import gridAnswer from "./gridAnswer";

export default combineReducers({
  form,
  currentUser,
  gridAnswer,
});
