import {
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
} from "@material-ui/core";
import {
  ExpandLess,
  ExpandMore,
  Folder as FolderIcon,
  Assignment as AssignmentIcon,
} from "@material-ui/icons";
import _trim from "lodash/trim";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { CardAnswer } from "../../../types/types";

export type Card = {
  id: number;
  path: string;
  cards: Card[];
  description: string;
  name: string;
};

type Props = {
  card: CardAnswer;
  level: number;
};

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    backgroundColor: theme.palette.grey[200],
    height: "100%",
    overflow: "auto",
  },
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    width: 0,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  nopadding: {
    padding: "0px",
  },
}));

function CardMenuItem(props: Props) {
  const { level, card } = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  const toggleOpen = (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    // this.setState(prevState => ({ ...prevState, open: !prevState.open }))
    setOpen((o) => !o);
  };

  const goToPath = () => {
    const encodedPath = `/${card.path
      .split("/")
      .map(_trim)
      .map(encodeURIComponent)
      .join("/")}`;
    history.push({ pathname: `${encodedPath}` });
  };

  const renderExpand = () => {
    if (card.cards.length > 0) {
      if (open) {
        return (
          <Grid onClick={toggleOpen} style={{ paddingLeft: 10 }}>
            <ExpandLess />
          </Grid>
        );
      } else {
        return (
          <Grid onClick={toggleOpen} style={{ paddingLeft: 10 }}>
            <ExpandMore />
          </Grid>
        );
      }
    }
  };

  return (
    <React.Fragment>
      <ListItem button onClick={goToPath}>
        <ListItemIcon>
          {card.cards.length > 0 ? <FolderIcon /> : <AssignmentIcon />}
        </ListItemIcon>
        <ListItemText
          inset
          primary={card.name}
          secondary={card.description}
          className={classes.nopadding}
        />
        {renderExpand()}
      </ListItem>
      {card.cards && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {card.cards.map((c) => (
              <CardMenuItem card={c} key={c.id} level={level + 1} />
            ))}
          </List>
        </Collapse>
      )}
    </React.Fragment>
  );
}

export default CardMenuItem;
